import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInner, SectionSubTitle } from './about.stc'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
// import {Animate} from "react-move";
// import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Button from '../../../components/shared/button';
import animation_cloudGIF from "../../../../src/assets/animations/animation_cloud.gif";

//queries the servicesdata.json file
const Section = ({HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies}) => {
    const servicesQueryData = useStaticQuery(graphql `
        query ServicesIntroDataQuery {
            servicesdataJson(jsonId: {eq: "services-content"}) {
                contents {
                    title
                    subtitle
                    content
                    link
                    buttonColor
                    image {
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }    
    `)
    const serviceData = servicesQueryData.servicesdataJson;
    const services = serviceData.contents;

    const content = services.map((service, index) =>
        <div className="row services" key={index}>
            {index%2 === 0 ?
                <div className="row" style={{width: "100%"}}>
                    <div className="col-2 offset-1">
                        <SectionInner>
                            <Fade left>
                                <img className="services-image" src={service.image.childImageSharp.fluid.src} alt={service.title} />
                            </Fade>
                        </SectionInner>
                    </div>
                    <div className="col-2 standard right-text">
                        <SectionInner>
                            <SectionTitleWrap>
                                <Heading {...SubHeadingStyle}>{service.subtitle}</Heading>
                                <Heading {...HeadingStyleServies}>{service.title}</Heading>
                                {service.content.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                                {service.link && <Button style={{background: service.buttonColor && service.buttonColor}}
                                    className="button" as={Link} to={service.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                                </Button>}
                            </SectionTitleWrap>
                        </SectionInner>
                    </div>
                    <div className="col-2 offset-1 adjusted right-text">
                        <SectionInner>
                            <SectionTitleWrap>
                                <Heading {...SubHeadingStyle}>{service.subtitle}</Heading>
                                <Heading {...HeadingStyleServies}>{service.title}</Heading>
                                {service.content.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                                {service.link && <Button style={{background: service.buttonColor && service.buttonColor}}
                                    className="button" as={Link} to={service.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                                </Button>}
                            </SectionTitleWrap>
                        </SectionInner>
                    </div>
                </div>
                :
                <div className="row reverse-on-mobile" style={{width: "100%"}}>
                    <div className="col-2 offset-1 left-text">
                        <SectionInner>
                            <SectionTitleWrap>
                                <Heading {...SubHeadingStyle}>{service.subtitle}</Heading>
                                <Heading {...HeadingStyleServies}>{service.title}</Heading>
                                {service.content.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                                {service.link && <Button style={{background: service.buttonColor && service.buttonColor}}
                                    className="button" as={Link} to={service.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                                </Button>}
                            </SectionTitleWrap>
                        </SectionInner>
                    </div>
                    <div className="col-2 standard">
                        <SectionInner>
                            <Fade right>
                            <img style={{marginLeft: "auto"}} className="services-image" src={service.image.childImageSharp.fluid.src} alt={service.title} />
                            </Fade>
                        </SectionInner>
                    </div>
                    <div className="col-2 offset-1 adjusted">
                        <SectionInner>
                            <Fade right>
                            <img style={{marginLeft: "auto"}} className="services-image" src={service.image.childImageSharp.fluid.src} alt={service.title} />
                            </Fade>
                        </SectionInner>
                    </div>
                </div>
            }
        </div>
    );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <div className="col-2 offset-1 left-text">
                    <SectionInner>
                        <SectionTitleWrap style={{paddingTop: "1vh"}}>
                            <Heading {...HeadingStyle} style={{paddingTop: "40px"}}>Services</Heading>
                            <SectionSubTitle>
                                <Heading {...SubHeadingStyle} style={{ color: 'white', paddingBottom: "30px"}}>Create the Future, Today</Heading>
                                <Text {...Paragraph} style={{ color: '#DDF7EA'}}>We design, build, and extend digital products, platforms and apps.
                            From mobile to web, from backend to frontend, from APIs to security. Talk to us today.</Text>
                               <Link to="/contact"><Button  color="secondary" className="button" layout={2}>Learn More <i className="ti-arrow-right"></i>
                                </Button></Link>
                            </SectionSubTitle>

                        </SectionTitleWrap>
                    </SectionInner>
                </div>
                <div className="col-2 standard">
                        <SectionInner>
                            <img className="service-image" src={ animation_cloudGIF } alt="services header"/>
                            {/*<img {...ImageSyle} src={require("../../../../src/assets/animations/digital-branding-service.gif")} />*/}
                        </SectionInner>
                </div>
                <div className="col-2 offset-1 adjusted">
                        <SectionInner>
                            <img className="service-image" src={ animation_cloudGIF } alt="services header"/>
                            {/*<img {...ImageSyle} src={require("../../../../src/assets/animations/digital-branding-service.gif")} />*/}
                        </SectionInner>
                </div>
            </div>

            {content}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#fff',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'slategray',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'slategray',
        fontSize: '16px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'black',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

