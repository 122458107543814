import React, { useState } from 'react'
import Heading from '../../components/shared/heading'
// import Text from '../../components/shared/text'
import { SectionTitleWrap } from "../company/join-our-team/join-our-team.stc"
import { SectionInner } from '../services/web-development/about.stc'
import PropTypes from "prop-types"
import { useForm } from "react-hook-form";
import Button from '../../components/shared/button'
import Form, { Input, Textarea, FormGroup } from '../../components/shared/form'
import { FormWrap } from './contact-form.stc'
import Helmet from 'react-helmet'
import firebase from "../../../firebase/firebase";
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
// import { useStaticQuery, graphql } from "gatsby"
// import Fade from 'react-reveal/Fade';

const Contact = ({HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies, inputStyle, textareaStyle, url}) => {

    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [token, setToken] = useState('')

    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    })
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const [value, setValue] = useState({
        name: '',
        email: '',
        number: '',
        subject: '',
        message: ''
    });

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
            setValue({
                name: '',
                email: '',
                number: '',
                subject: '',
                message: ''
            })
        }
    };
    const onSubmit = async (data, e) => {
        const form = e.target;
        // console.log(data)
        // setServerState({ submitting: true });
        // // const response = await axios.post("https://us-central1-fir-functions-api-7fcdb.cloudfunctions.net/user", data
        // // ).catch(err => {
        // //     handleServerResponse(false, err.response.data.error, form);
        // // });
        // // const response1 = await axios.post("https://getform.io/f/076cc519-914a-4ec9-9205-451872073e07", data
        // // ).catch(err => {
        // //     handleServerResponse(false, err.response.data.error, form);
        // // });
        // const result = await executeRecaptcha('homepage')
        // setToken(result) //--> grab the generated token by the reCAPTCHA
        // // Prepare the data for the server, specifically body-parser
        //
        // const r = await fetch(
        //     "https://www.google.com/recaptcha/api/siteverify",
        //     {
        //         method: "POST",
        //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //         body: `secret=6LcwKY8aAAAAAKAnyBvAgtRLnKYLXOGAbsGRBLSH&response=${result}`
        //     }
        // );
        //
        // const json = await r.json();
        //
        // // const body = JSON.stringify({ result });
        // console.log(r);
        // // POST request to your server
        // // fetch('/submit', {
        // //     method: 'POST',
        // //     headers: {
        // //         'Accept': 'application/json, text/plain, */*',
        // //         'Content-type': 'application/json'
        // //     },
        // //     body //--> this contains your data
        // // })
        // //     .then(res => res.json())
        // //     .then(data => {
        // //         console.log(data);
        // //         // setNotification(data.msg) //--> dynamically set your notification state via the server
        // //     })

        firebase
            .firestore()
            .collection("Enquiries")
            .add(data)
            .then(() => alert('We will be in touch shortly')).catch(e => console.log(e));
        console.log(data);
        handleServerResponse(true, "Thanks! for being with us", form);
    }

    const onChangeHandler = e => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    return (
        <div className="row services" style={{backgroundColor: 'darkgrey'}}>
            <div className="col-4 offset-1"
                 style={{
                     borderRadius: '10px',
                     backgroundColor: 'white',
                     padding: '20px',
                     marginTop: '10vh',
                     marginBottom: '10vh'}}
            >
                <SectionInner style={{height: 'auto', padding: '3vw 5vw'}}>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>Get in touch with us</Heading>
                    </SectionTitleWrap>
                </SectionInner>

                <div>
                    <Helmet>
                        <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer>
                        </script>
                    </Helmet>
                    <FormWrap>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <FormGroup className="halfwidth" style={{ paddingRight: '2vw'}}>
                                    <Input
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        placeholder="First name"
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'First name*',
                                        })}
                                        {...inputStyle}
                                    />
                                    {errors.name && <span className="error">{errors.name.message}</span>}
                                </FormGroup>
                                <FormGroup className="halfwidth">
                                    <Input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        placeholder="Last name"
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'Last name*',
                                        })}
                                        {...inputStyle}
                                    />
                                    {errors.name && <span className="error">{errors.name.message}</span>}
                                </FormGroup>
                                <FormGroup className="halfwidth" style={{ paddingRight: '2vw'}}>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'Email*',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "invalid email address"
                                            }
                                        })}
                                    />
                                    {errors.email && <span className="error">{errors.email.message}</span>}
                                </FormGroup>
                                <FormGroup className="halfwidth">
                                    <Input
                                        type="text"
                                        name="number"
                                        id="number"
                                        placeholder="Phone Number"
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'Number*',
                                            pattern: {
                                                value: /^[+]*\d{7,15}/g,
                                                message: "invalid number"
                                            }
                                        })}
                                        {...inputStyle}
                                    />
                                    {errors.number && <span className="error">{errors.number.message}</span>}
                                </FormGroup>
                                <FormGroup className="halfwidth"  style={{ paddingRight: '2vw'}}>
                                    <Input
                                        type="text"
                                        name="companyname"
                                        id="companyname"
                                        placeholder="Company name"
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'Company*',
                                            pattern: {
                                                message: "invalid Company"
                                            }
                                        })}
                                        {...inputStyle}
                                        />
                                    {errors.companyname && <span className="error">{errors.companyname.message}</span>}
                                </FormGroup>
                                <FormGroup style={{
                                        height: "8rem",
                                        }}>
                                        <p 
                                            className="required"
                                            style={{ 
                                                fontSize: "13px",
                                                marginTop: "1rem",
                                                marginBottom: "5px",
                                                color: "#454545"
                                            }}
                                            >How can we help you?
                                        </p>
                                    <Textarea
                                        className="textarea"
                                        name="message"
                                        id="textarea"
                                        placeholder="Tell us about your project..."
                                        onChange={onChangeHandler}
                                        ref={register({
                                            required: 'Message*',
                                            minLength: { value: 10, message: "Minimum length is 10" }
                                        })}
                                    />
                                    {errors.message && <span className="error">{errors.message.message}</span>}
                                </FormGroup>
                                {/* </div>
                                <div className="form-row">
                                    <FormGroup {...textareaStyle}>
                                        <Textarea
                                            name="message"
                                            placeholder="Your Message"
                                            onChange={onChangeHandler}
                                            ref={register({
                                                required: 'Message Required',
                                                minLength: { value: 10, message: "Minimum length is 10" }
                                            })}
                                        />
                                        {errors.message && <span className="error">{errors.message.message}</span>}
                                    </FormGroup>
                                </div>
                                <div className="form-row" style={{marginTop: '2vh'}}>
                                    <div className="g-recaptcha" data-sitekey={"6LdJMY8aAAAAAIWdBop76fza5cRPTj72nQ0vDTE-"}>
                                </div> */}
                                <FormGroup style={{marginBottom: '10vh'}}>
                                    <Button className="button" type="submit" disabled={serverState.submitting} layout={2}>Get in touch<i className="ti-arrow-right"></i></Button>
                                    {serverState.status && (
                                        <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                                            {serverState.status.msg}
                                        </p>
                                    )}
                                </FormGroup>
                            </div>
                        </Form>
                    </FormWrap>
                </div>

            </div>

        </div>
    )

}

Contact.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    aiServiceLeft: PropTypes.object,
    inputStyle: PropTypes.object,
    textareaStyle: PropTypes.object
}

Contact.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '16px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    inputStyle: {
        responsive: {
            xsmall: {
                mb: '20px'
            }
        }
    },
    textareaStyle: {
        mt: '40px',
        mb: '40px',
        responsive: {
            xsmall: {
                mt: '25px',
                mb: '25px'
            }
        }
    }
}

export default Contact
