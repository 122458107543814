import styled from 'styled-components';
import {device} from '../../../theme'

export const AboutSectionWrap = styled.div `
    padding-top: 160px;
    background-color: black;

    .section-pt-md{
        padding-top: 70px;
    }
    .col-1.offset-custom{
        margin-left: 5vw;
    }
    .services{
        padding-top: 15vh;
        padding-bottom: 15vw;
        background-color: white;
    }
    .service-top-section{
        margin-bottom: 20vh;
    }
    .service-image{
        max-width: 310px;
        margin: auto;
    }
    .services-image{
        max-width:300px;
    }
    .adjusted{
        display: none;
    }
    .left-text{
        padding-right: 10vh;
    }
    .right-text{
        padding-left: 10vh;
    }
    @media ${device.xlarge}{
        .col-1.offset-custom{
            margin-left: 5vw;
        }
    }
    @media ${device.large}{
        .col-1.offset-custom{
            margin-left: 5vw;
        }
    }
    @media ${device.medium}{
        padding: 70px 0;
        .col-1.offset-1, .col-2.offset-1 {
            max-width: 80vw;
            min-width: 80vw;
            flex: 0;
            margin-left: 10vw;
        }
        .col-2.offset-custom{
            margin-left: 10vw;
        }
        .reverse-on-mobile {
            flex-flow: column-reverse;
        }
        .section-pt-md{
            padding-top: 15px;
        }
        .project-type{
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;
            margin: 30px 0;
        }
        .service-image{
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
        .services-image{
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
        }
        .standard{
            display: none;
        }
        .adjusted{
            display: block;
        }
        .left-text{
            padding-right: unset;
            text-align: center;
        }
        .right-text{
            padding-left: unset;
            text-align: center;
        }
    }
    @media ${device.small}{
        padding: 20vw 0;
        .project-type{
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            margin: 0;
            padding: 15px 0;
        }
        .service-image{
            width: 200px;
            margin-top: 30px;
        }
        .services-image{
            max-width:200px;
        }
    }
`;

export const AboutContent = styled.div `
    margin-top: -7px;
    margin-bottom: -18px;
`;

export const ProjectTypeList = styled.ul `
    margin: 0;
    padding-left: 17px;
`;


export const SectionTitle = styled.div `
    margin-top: 10px;
`;

export const SectionSubTitle = styled.div `
    margin-top: 10vh;
    @media ${device.medium}{
        margin-top: 5vh;
    }
    @media ${device.small}{
        margin-top: 1vh;
    }
`;

export const SectionInner = styled.div `
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;
