import styled from 'styled-components';
import {device} from '../../theme'

export const FormWrap = styled.div`
    .form-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .halfwidth{
        width: 50%;
    }
    .error{
        margin-top: 10px;
        display: block;
        color: red;
    }
    .form-output{
        margin-top: 10px;
    }
    .success{
        color: green;
    }
    @media ${device.largest}{
        padding: 5vw;
        margin-top: -10vh
    }
    @media ${device.xlarge}{
        padding: 5vw;
        margin-top: -10vh
    }
    @media ${device.medium}{
        padding: 5vw;
        margin-top: -5vh
    }
    @media ${device.small}{
        padding: 5vw;
        margin-top: 1vh
    }
`;