import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Section from '../containers/services/section'
import Contact from '../containers/footer-contact-form';

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Software and Data Engineering Services" description="We provide Services in Software Engineering for Web and Mobile App Development, API and Database Development to Data Engineering, Data Science, Artificial Intelligence and Machine Learning."/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
        <Contact/>
    </Layout>
)

